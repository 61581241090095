<template>
  <div class="treatment-effect">
    <div class="head-box">
      <span
        :class="['head-item', type == 1 ? 'head-item-active' : '']"
        @click="() => typeCut(1)"
        >畜禽粪污生产点</span
      >
      <span
        :class="['head-item', type == 2 ? 'head-item-active' : '']"
        @click="() => typeCut(2)"
        >还田土壤墒情监测</span
      >
      <span
        :class="['head-item', type == 3 ? 'head-item-active' : '']"
        @click="() => typeCut(3)"
        >河道水体质量监测</span
      >
      <div
        class="line"
        :style="{ left: type == 1 ? '55px' : type == 2 ? '215px' : '375px' }"
      ></div>
    </div>
    <div class="content-box">
      <div class="sel-box">
        <!-- <Select
          v-model="farmId"
          placeholder="请选择养殖场"
          style="width: 200px"
          filterable
        >
          <Option
            v-for="(item, index) in farms"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select> -->
        <Input
          v-model="farmName"
          placeholder="请选择养殖场"
          style="width: 200px"
          @on-focus="selFarm"
        ></Input>
      </div>
      <div style="width: 100%; height: 94px; padding-bottom: 16px">
        <scroll-box direction="x">
          <div class="monitor-value-box">
            <div
              :class="[
                'monitor-value-item',
                currentMonitor && currentMonitor.id == item.id
                  ? 'monitor-value-item-active'
                  : '',
              ]"
              v-for="item in monitorData"
              :key="item.id"
              @click="() => (currentMonitor = item)"
            >
              <img :src="getImgUrl(item.servicePath, item.imgUrl)" alt="" />
              <div class="value">
                <p class="num">{{ item.measure }}</p>
                <p>
                  {{ `${item.sensorCategoryName}(${item.sensorCategoryUnit})` }}
                </p>
              </div>
            </div>
          </div>
        </scroll-box>
      </div>
      <div class="chart-filter-box">
        <p>
          {{ currentFarm.name
          }}{{ type == 1 ? "环境监测" : type == 2 ? "墒情监测" : "水质监测" }}
        </p>
        <div class="monitor-value-info">
          <span
            >最后更新时间{{
              currentMonitor ? currentMonitor.updateTime : "--"
            }}</span
          >
          <span class="monitor-value">{{
            currentMonitor
              ? currentMonitor.measure + currentMonitor.sensorCategoryUnit
              : "--"
          }}</span>
          <span
            >{{
              currentMonitor ? currentMonitor.sensorCategoryName : "--"
            }}参考值({{
              currentMonitor
                ? `${currentMonitor.categoryMinRange} ~ ${currentMonitor.categoryMaxRange}${currentMonitor.sensorCategoryUnit}`
                : "--"
            }})</span
          >
        </div>
        <div class="filter-box">
          <span
            :class="[
              'filter-item',
              monitorType == 'day' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('day')"
            >今日</span
          >
          <span
            :class="[
              'filter-item',
              monitorType == 'week' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('week')"
            >本周</span
          >
          <span
            :class="[
              'filter-item',
              monitorType == 'month' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('month')"
            >本月</span
          >
          <span
            :class="[
              'filter-item',
              monitorType == 'year' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('year')"
            >本年</span
          >
          <DatePicker
            type="daterange"
            style="width: 200px"
            :options="options"
            v-model="monitorDate"
            placement="left-start"
          ></DatePicker>
        </div>
      </div>
      <div class="chart" ref="chart"></div>
      <div class="table">
        <x-table
          :columns="table.columns"
          :data="table.data"
          :loading="table.loading"
        ></x-table>
      </div>
      <div class="footer">
        <Page
          :current="page.pageNo"
          :page-size="page.pageSize"
          :total="page.total"
          :show-elevator="true"
          :show-total="true"
        ></Page>
      </div>
    </div>

    <select-farm
      v-model="modal"
      :farmId="farmId"
      :options="options"
      isDefault
      @sel-finish="selFinish"
    ></select-farm>
  </div>
</template>

<script>
import * as echarts from "echarts";
import scrollBox from "@/components/scrollBox";
import moment from "moment";
import selectFarm from "@/components/selectFarm";
export default {
  name: "",
  components: {
    scrollBox,
    selectFarm,
  },
  data() {
    return {
      type: 1,
      monitorType: "year",
      monitorDate: [],
      options: {
        disabledDate(date) {
          if (moment(date).isAfter(moment(new Date()).subtract(1, "days")))
            return true;
          return false;
        },
      },

      chart: null,
      table: {
        columns: [
          {
            title: "养殖场名称",
          },
          {
            title: "养殖区",
          },
          {
            title: "监测指标",
          },
          {
            title: "监测值",
          },
          {
            title: "参考范围",
          },
          {
            title: "上传时间",
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      farms: [],
      farmId: "",
      farmName: "",
      currentFarm: {},
      monitorData: [],
      currentMonitor: null,
      statData: [],
      modal: false,
      options: {
        siteType: "3",
      },
    };
  },
  watch: {
    farmId(n) {
      if (!n) return;
      this.getMonitor();
    },
    currentMonitor() {
      this.getStatData();
    },
    monitorDate(n) {
      if (!n[0]) return;
      this.monitorType = "custom";
      this.getStatData();
    },
  },
  methods: {
    selFarm() {
      this.modal = true;
    },
    typeCut(type) {
      if (this.type == type) return;
      this.type = type;
      if (!this.farmId) return;
      this.getMonitor();
    },
    getFarm() {
      this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST, {
        siteType: "2",
        pageNo: 1,
        pageSize: 1,
      }).then((res) => {
        this.farms = res.list;
        this.farmId = res.list[0]?.id ?? "";
        this.farmName = res.list[0].name ?? "";
        this.currentFarm = res.list[0];
      });
    },
    selFinish(farm) {
      this.currentFarm = farm;
      this.farmId = farm.id;
      this.farmName = farm.name;
      this.modal = false;
    },
    getMonitor() {
      this.$post(this.$api.MONITOR.TREATMENT_EFFECT_MONITOR, {
        farmId: this.farmId,
        sensorType: this.type,
      }).then((res) => {
        this.monitorData = res.list;
        this.currentMonitor = res.list[0];
      });
    },
    getStatData() {
      this.$post(this.$api.MONITOR.MONITOR_CHART_NEW, {
        guid: this.currentMonitor.guid,
        switchType: this.monitorType,
        sBeginTime:
          this.monitorType == "custom"
            ? moment(this.monitorDate[0]).format("YYYY-MM-DD")
            : "",
        sEndTime:
          this.monitorType == "custom"
            ? moment(this.monitorDate[1]).format("YYYY-MM-DD")
            : "",
        days:
          this.monitorType == "week"
            ? 7
            : this.monitorType == "month"
            ? 30
            : this.monitorType == "custom"
            ? moment(this.monitorDate[1]).diff(
                moment(this.monitorDate[0]),
                "days"
              ) + 1
            : "",
      }).then((res) => {
        this.statData = res || [];
        this.initChart();
      });
    },
    monitorCut(type) {
      if (this.monitorType == type) return;
      this.monitorType = type;
      this.getStatData();
    },

    initChart() {
      let xData = this.statData[0].child.map((el) => el.showAllTime);
      let data = this.statData[0].child.map((el) => el.value);
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart);
      }
      let option = {
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: xData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#0E9CFF"],
        series: {
          type: "line",
          smooth: "true",
          data,
          markArea: {
            silent: true,
            data: [
              [
                {
                  yAxis: this.currentMonitor.categoryMinRange,
                },
                {
                  yAxis: this.currentMonitor.categoryMaxRange,
                },
              ],
            ],
            itemStyle: {
              color: "rgba(77, 203, 185, 0.4)",
            },
          },
        },
      };
      this.chart.setOption(option);
    },
    resize() {
      this.chart.resize();
    },
  },
  mounted() {
    // this.getFarm();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>